<template>
    <div id="app">
      <router-view></router-view>
      
      <BackTop></BackTop>

    </div>
</template>

<script>
  export default {
    name: 'app',
  }
</script>

<style>
a {
  text-decoration: none;
  color: inherit;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
  color: inherit;
}

.ivu-table .demo-table-info-row td{
    background-color: #f8d6d2;
    color: #000;
}

.ivu-table .demo-table-error-row td{
    background-color: #f9fce6;
    color: #333;
}

.ivu-table .demo-table-limit-row td{
    background-color: #ce2525;
    color: #eee;
}

.ivu-table .demo-table-danger-row td{
    background-color: #dfe76d;
    color:#111;
}

</style>