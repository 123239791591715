import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routerConfig'
import axios from 'axios'
import { BASE_URL_SERVER } from '../main.js'

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
    mode: 'history',
    scrollBehavior: () => ({ y: 0, x: 0 }),
    routes
})

const validRoute = ["login","index"]

let userToken = localStorage.getItem("current_user_token");

function forceToLogin() {
    window.location.href = "/login";
}

function forceToManage(value) {
    router.push({ name: value});
}

router.beforeEach((to, from, next) => {
    if (to.path === '/') {
        window.location.href = '/index';
    }
    /*if (to.name !== "login" && to.name !== "index" && to.name !== "manageIndex" && to.name !== "register" && to.name !== "system_login" && to.name !== "system_register") {
        axios.post(BASE_URL_SERVER + '/get_authority', { userToken: userToken, target: to.name })
            .then(response => {
                if (response.data !== "authority_pass") {
                    window.location.href = '/system_login';
                } else {
                    next();
                }
            });
    } else {
        next();
    }*/
    if(validRoute.indexOf(to.name)!==-1){
        next();
    }else if (validRoute.indexOf(to.name) === -1 && userToken) {
        let authData = localStorage.getItem('authData');
        let serverExpireTime = new Date(JSON.parse(window.atob(userToken.split('.')[0])).exp);
        if (!authData || Date.now() > serverExpireTime) {
            forceToLogin();
        } else {
            let authObject = JSON.parse(authData);
            if (Date.now() > authObject.expireTime) {
                axios.post(
                    BASE_URL_SERVER + "/base_authority",
                    { userToken: userToken }
                ).then(response => {
                    if (response.data.stat !== "authority_pass") {
                        forceToLogin();
                    } else {
                        let resAuthData = {
                            barRights: response.data.barRights,
                            sfileRight: response.data.sfileRight,
                            expireTime: Date.now() + 1000 * 3600 * 4,
                        };
                        localStorage.setItem('authData', JSON.stringify(resAuthData));
                        if (resAuthData.barRights.indexOf(to.name) !== -1 || resAuthData.barRights.indexOf('super_all') !== -1) {
                            next();
                        }else if(resAuthData.barRights.indexOf('accountCenter') !== -1){
                            forceToManage(resAuthData.barRights[1]);
                        } else {
                            forceToLogin();
                        }
                    }
                });
            } else {
                if (authObject.barRights.indexOf(to.name) !== -1 || authObject.barRights.indexOf('super_all') !== -1) {
                    next();
                } else if(authObject.barRights.indexOf('accountCenter') !== -1){
                    forceToManage(authObject.barRights[1]);
                } else {
                    forceToLogin();
                }
            }
        }
    } else if(!userToken){
        forceToLogin();
    }else {
        next();
    }

})

export default router